<script>
import { useStore } from 'vuex'
import useProgram from '@/composables/program'
import { onMounted, ref } from 'vue'
import FormBotoes from '@/components/forms_bottom_buttons/index.vue'
import CustomInput from '@/components/custom_input/index.vue'
import { callFunction } from '@/helpers/callFunctionFormulario'
import { v4 as uuidv4 } from 'uuid'
import uploadFile from '@/components/file/upload-file.vue'
import isUnsuportedFileExtension from '@/helpers/isUnsuportedFileExtension';
import Swal from 'sweetalert2'
import { getValueListById } from '@/helpers/forms/getListaValorById'
import { useRoute } from 'vue-router'
import { sendFunctionRequest } from '@/helpers/dynamicTable/sendFunctionRequest'
import router from '@/router'
import AzureHelper from '@/helpers/azure'

export default {
  props: {
    program_schema: {
      type: Object,
      required: true,
    },
    dados: {
      type: Object,
      required: true,
    },
    registro_id: {
      type: String,
      required: false,
    },
    inModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    onSuccess: {
      type: Function,
      required: false,
    },
    formType_: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const store = useStore()
    const browser_route = useRoute()
    const formType = props.formType_ ? props.formType_ : store.state.programForm.formType
    const files = ref([])
    const showUploadFileModal = ref(false)
    const attachedFiles = ref([])
    const contaFinanceiraPessoaOptionsList = ref([])
    const formRef = ref(null)

    const programa = {
      program_schema: props.program_schema,
      dados: props.dados,
      registro_id: props.registro_id
    }
    const function_add_document = ref(programa
      .program_schema
      .sis_funcionalidade
      .find(sf => sf.id_funcionalidade == 'dd34d8e4-d993-4e6b-8c99-a411a959550a')
    )

    const {
      setProgramForm,
      addNewDetailTableForm,
      removeDetailTableForm,
      handleFormSubmit,
      sis_detail_table,
      baseFormModifier,
      loading,
      btnloading,
      resetMainAndDetailTableForms
    } = useProgram(formType, programa)

    onMounted(() => {
      setProgramForm();
    });

    const setShowUploadFileModal = () => {
      files.value = []
      showUploadFileModal.value = true
    }

    const attachFiles = () => {
      attachedFiles.value.push(...files.value)
      showUploadFileModal.value = false
      files.value = []
    }

    const generateURL = (file) => {
      if (file.type != 'application/pdf') {
        let fileSrc = URL.createObjectURL(file)
        setTimeout(() => {
          URL.revokeObjectURL(fileSrc)
        }, 1000)
        return fileSrc
      }
      else {
        let fileSrc = new String(URL.createObjectURL(file))
        setTimeout(() => {
          URL.revokeObjectURL(fileSrc)
        }, 1000)
        return fileSrc
      }
    }

    const setContaFinanceiraPessoaOptionsList = () => {
      if (!baseFormModifier.value.mutatedValues.id_credor_devedor || isNaN(baseFormModifier.value.mutatedValues.id_credor_devedor)) {
        baseFormModifier.value.setMutatedValues('id_conta_financeira_pessoa', null)
        contaFinanceiraPessoaOptionsList.value = []
        return
      }
      getValueListById('eab47076-92eb-466b-bc55-878f82a72fb0', 'id_pessoa = ' + baseFormModifier.value.mutatedValues.id_credor_devedor)
        .then(res => {
          baseFormModifier.value.setMutatedValues('id_conta_financeira_pessoa', null)
          const { sis_lista_valor: { sis_opcao_lista_valor } } = res
          contaFinanceiraPessoaOptionsList.value = sis_opcao_lista_valor ?? []
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: error,
            confirmButtonColor: "#34c38f",
            confirmButtonText: "OK",
          });
          console.log(error)
        })
    }

    async function saveFiles(register) {
      const documentsToUpload = attachedFiles.value.map(file => {
        return {
          nome_documento: file.name,
          arquivo_base64: null,
          tamanho: file.size,
          descricao: file.descricao,
          id_empresa: register.id_empresa,
        }
      });

      const primary_key_name = programa.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome
      const body = {
        id: register[primary_key_name] + '',
        id_programa: browser_route.params.targetId,
        condicao_adicional: "",
        lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
        data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
        navegador: false,
        ordenacao: null,
        quantidade_registros: 100,
        pagina: 0,
        dados: {
          sis_documento: {
            registros: [...documentsToUpload],
          },
          [programa.program_schema.sis_tabela.nome_tabela]: {
            registros: [
              {
                [primary_key_name]: register[primary_key_name]
              },
            ],
          },
        },
        rota: programa.program_schema.rota_backend + function_add_document.value.rota_backend,
      }

      await sendFunctionRequest(body,
        async (res) => {
          Swal.fire({
            icon: "success",
            text: "Salvo com sucesso!",
            confirmButtonColor: "#34c38f",
            confirmButtonText: "OK",
          });
          const query = Object.assign({}, browser_route.query);
          query.registro_id = register[primary_key_name];
          query.tipo = 'edicao';
          await router.push({ query });
          store.commit('programForm/setFormType', 'edition');

          const documents = res.data.retorno.dados.sis_documento.registros
          const documentsToUploadAzure = []
          attachedFiles.value.forEach(file => {
            const filteredDocuments = documents
              .filter(doc => doc.nome_documento == file.name && doc.tamanho == file.size)
              .sort((a, b) => new Date(b.data_inclusao) - new Date(a.data_inclusao))
            const document = filteredDocuments[0] ?? null
            if (document) {
              document.file = file
              documentsToUploadAzure.push(document)
            }
          })

          const azurehelper = new AzureHelper()
          await azurehelper.uploadDocuments(documentsToUploadAzure)
        },
        async (error) => {
          Swal.fire({
            icon: "error",
            text: error,
            confirmButtonColor: "#34c38f",
            confirmButtonText: "OK",
          });
          const query = Object.assign({}, browser_route.query);
          query.registro_id = register[primary_key_name];
          query.tipo = 'edicao';
          await router.push({ query });
          store.commit('programForm/setFormType', 'edition');
        }
      )
    }

    const save = async (onSuccess) => {
      btnloading.value = true;
      let res = null
      res = await handleFormSubmit({ target: formRef.value }, null, null, false, true, onSuccess)
      if (res) {
        if (!res.status || res.error) {
          Swal.fire({
            icon: "error",
            text: res.mensagem ? res.mensagem : res.erro,
            confirmButtonColor: "#34c38f",
            confirmButtonText: "OK",
          });
        }
        if (res.status && attachedFiles.value.length) {
          const { dados } = res.retorno
          const register = dados[programa.program_schema.sis_tabela.nome_tabela].registros[0]
          await saveFiles(register)
        } else if (res.status) {
          const { dados } = res.retorno
          const register = dados[programa.program_schema.sis_tabela.nome_tabela].registros[0]
          const primary_key_name = programa.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome
          const query = Object.assign({}, browser_route.query);
          query.registro_id = register[primary_key_name];
          query.tipo = 'edicao';
          await router.push({ query });
          store.commit('programForm/setFormType', 'edition');
        }
      }
      btnloading.value = false;
    }

    const showBankSlipModal = async () => {
      let list_id = "dbef0fc7-2426-4269-b844-019d83df9370";
      let condition = "";
      let preAditionalCondition = `CAST(TO_JSONB(vw_debito_direto_autorizado.*) AS TEXT) ~* `;
      const res = await getValueListById(list_id, condition);
      store.commit("massActionsModals/setProperty", [
        {
          key: "showDynamicFilterValueListModal",
          value: true,
        },
        {
          key: "preAditionalCondition",
          value: preAditionalCondition,
        },
        {
          key: "sis_lista_valor",
          value: res.sis_lista_valor,
        },
        {
          key: "onSave",
          value: (ids_object) => {
            const codigo_barra = ids_object.vw_debito_direto_autorizado.registros[0].linha_digitavel.replaceAll('.', '').replaceAll(' ', '')
            baseFormModifier.value.setMutatedValues('codigo_barras_boleto', codigo_barra)
          },
        },
      ]);
    }

    return {
      showBankSlipModal,
      save,
      setContaFinanceiraPessoaOptionsList,
      contaFinanceiraPessoaOptionsList,
      generateURL,
      isUnsuportedFileExtension,
      attachFiles,
      files,
      showUploadFileModal,
      setShowUploadFileModal,
      attachedFiles,
      formId: uuidv4(),
      formRef,
      programa,
      formType,
      loading,
      btnloading,
      handleFormSubmit,
      baseFormModifier,
      sis_detail_table,
      addNewDetailTableForm,
      removeDetailTableForm,
      callFunction,
      resetForms: (dados) => {
        programa.dados = dados
        resetMainAndDetailTableForms(dados)
      }
    };
  },
  components: {
    FormBotoes,
    CustomInput,
    uploadFile,
  }
}
</script>
<template>
  <div class="row">
    <template v-if="loading">
      <div class="text-center">
        <b-spinner variant="primary"></b-spinner>
      </div>
    </template>
    <form v-else @submit.prevent="save(this.$props.onSuccess)" class="needs-validation" novalidate ref="formRef"
      :id="formId">
      <div class="row pb-4 mb-4">
        <div class="col-xxl-9">
          <div class="card mb-0 mt-4">
            <div class="card-header d-flex align-items-center justify-content-between" style="height:70px;">
              <div class="d-flex align-items-center">
                <h6 class="card-title fw-semibold mb-0 me-2">{{ program_schema.sis_tabela.denominacao }}</h6>
              </div>
            </div>
            <div class="card-body" style="min-height:482px;">
              <div class="row border mb-2 py-3 mx-1">
                <div class="col-12 col-md-6">
                  <div class="mb-3 flex-1">
                    <CustomInput :formState="baseFormModifier" :formType="formType"
                      :columnSchema="baseFormModifier.getColumnByName('id_empresa')" :showLabel="true" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <CustomInput :formState="baseFormModifier" :formType="formType"
                      :columnSchema="baseFormModifier.getColumnByName('natureza_titulo')" :showLabel="true" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3 flex-1">
                    <CustomInput :formState="baseFormModifier" :formType="formType"
                      :columnSchema="baseFormModifier.getColumnByName('id_credor_devedor')" :showLabel="true"
                      :onValueChange="setContaFinanceiraPessoaOptionsList" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <CustomInput :formState="baseFormModifier" :formType="formType"
                      :columnSchema="baseFormModifier.getColumnByName('id_tipo_cobranca')" :showLabel="true" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3 flex-1">
                    <CustomInput :formState="baseFormModifier" :formType="formType"
                      :columnSchema="baseFormModifier.getColumnByName('numero_titulo')" :showLabel="true" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <CustomInput :formState="baseFormModifier" :formType="formType"
                      :columnSchema="baseFormModifier.getColumnByName('qtd_parcela')" :showLabel="true" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3 flex-1">
                    <CustomInput :formState="baseFormModifier" :formType="formType"
                      :columnSchema="baseFormModifier.getColumnByName('data_emissao')" :showLabel="true" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <CustomInput :formState="baseFormModifier" :formType="formType"
                      :columnSchema="baseFormModifier.getColumnByName('data_vencimento')" :showLabel="true" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3 flex-1">
                    <CustomInput :formState="baseFormModifier" :formType="formType"
                      :columnSchema="baseFormModifier.getColumnByName('id_competencia')" :showLabel="true" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3 flex-1">
                    <CustomInput :formState="baseFormModifier" :formType="formType"
                      :columnSchema="baseFormModifier.getColumnByName('registra_chamado')" :showLabel="true" />
                  </div>
                </div>
              </div>
              <div class="row border mb-2 py-3 mx-1">
                <template
                  v-if="[1446489987, 1474606972, 256796645].includes(baseFormModifier.mutatedValues.id_tipo_cobranca)">
                  <div class="col-12 col-md-6">
                    <div class="mb-3">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('id_conta_financeira_pessoa')" :showLabel="true"
                        :customOptionsList="contaFinanceiraPessoaOptionsList" />
                      <span v-if="!baseFormModifier.mutatedValues.id_credor_devedor" class="text-danger">Por favor,
                        selecione um credor</span>
                    </div>
                  </div>
                  <div class="col-12 col-md-6"></div>
                </template>
                <!-- <template v-else-if="[3, 1446489765].includes(baseFormModifier.mutatedValues.id_tipo_cobranca)">
                  <div class="col-12 col-md-6">
                    <div class="mb-3">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('codigo_barras_boleto')" :showLabel="true">
                        <template v-slot:append>
                          <button @click="showBankSlipModal" type="button"
                            class="btn btn-icon btn-light text-primary mt-auto ms-1 border" v-b-tooltip.hover.top="'Pesquisar no DDA'">
                            <i class="ri-search-eye-line fs-16"></i>
                          </button>
                        </template>
                      </CustomInput>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="mb-3">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('boleto_emitido')" :showLabel="true" />
                    </div>
                  </div>
                </template> -->

                <div class="col-12 col-md-3">
                  <div class="mb-3">
                    <CustomInput :formState="baseFormModifier" :formType="formType"
                      :columnSchema="baseFormModifier.getColumnByName('valor_titulo')" :showLabel="true" />
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="mb-3">
                    <CustomInput :formState="baseFormModifier" :formType="formType"
                      :columnSchema="baseFormModifier.getColumnByName('juros_cobranca')" :showLabel="true" />
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="mb-3">
                    <CustomInput :formState="baseFormModifier" :formType="formType"
                      :columnSchema="baseFormModifier.getColumnByName('multa_cobranca')" :showLabel="true" />
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="mb-3">
                    <CustomInput :formState="baseFormModifier" :formType="formType"
                      :columnSchema="baseFormModifier.getColumnByName('desconto_cobranca')" :showLabel="true" />
                  </div>
                </div>

                <div class="col-12 col-md-12">
                  <div class="mb-3 flex-1">
                    <CustomInput :formState="baseFormModifier" :formType="formType"
                      :columnSchema="baseFormModifier.getColumnByName('observacao')" :showLabel="true" :textArea="true"
                      :textAreaRowsNumber="4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-3">
          <div class="card mt-4 mb-2">
            <div class="card-header d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <h6 class="card-title fw-semibold mb-0 me-2">Anexos</h6>
                <button type="button" @click="setShowUploadFileModal"
                  class="btn btn-ghost-secondary btn-icon btn-sm fs-18" v-b-tooltip.hover.top="'Anexar documentos'"><i
                    class="mdi link-success mdi-file-plus align-bottom"></i></button>
              </div>
              <span class="small text-muted">{{ attachedFiles.length }} Anexo(s)</span>
            </div>
            <div class="card-body">
              <div data-simplebar style="min-height: 450px;max-height:700px;" class="px-3 mx-n3">
                <div v-for="file, index in attachedFiles" :key="index"
                  :class="['preview-card border rounded mb-2 position-relative form-control', index === 0 ? 'mt-4' : '']">
                  <div class="d-flex flex-column align-items-center flex-wrap  p-2 justify-content-center text-center">
                    <div
                      class="border-dashed border p-2 mx-1 text-center d-flex justify-content-center align-items-center"
                      style="height: 65vh" v-if="isUnsuportedFileExtension(file.nome_documento)">
                      <p>Visualização indisponível</p>
                    </div>
                    <iframe v-else-if="file.type === 'application/pdf'" class="img-thumbnail me-2"
                      data-holder-rendered="true" frameBorder="0" scrolling="no" alt="200x200" width="200"
                      :src="generateURL(file)" />
                    <b-img v-else-if="['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)"
                      class="img-thumbnail me-2" alt="200x200" width="200" :src="generateURL(file)"
                      data-holder-rendered="true" />
                    <img v-else-if="['video/mp4', 'video/quicktime'].includes(file.type)" class="img-thumbnail me-2"
                      alt="100x200" height="100px" width="200" src="@/assets/images/video_file_type.svg"
                      data-holder-rendered="true" />

                    <div class="flex-grow-1" style="min-width:max-content;">
                      <div class="pt-1">
                        <h5 class="fs-11 mb-1" data-dz-name="">
                          {{ file.name }}
                        </h5>
                        <p v-if="file.size" class="fs-9 text-muted mb-0" data-dz-size="">
                          <strong>{{ ((file.size / 1024) / 1000).toFixed(2) }}</strong> MB
                        </p>
                      </div>
                    </div>
                    <div class="flex-shrink-0 mt-2 ms-lg-3 position-absolute" style="top:-15px;right:-10px;">
                      <b-button-group>
                        <button type="button" @click="attachedFiles.splice(index, 1)"
                          class="bg-white btn btn-white border rounded-circle btn-icon btn-sm fs-20"
                          v-b-tooltip.hover.top="'Remover Anexo'">
                          <i class="link-danger ri-delete-bin-5-fill align-bottom"></i>
                        </button>
                      </b-button-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FormBotoes :formType="formType" :loading="btnloading" :inModal="inModal" />
      </div>
    </form>
    <b-modal id="contas-pagar-criacao-upload" title="Adicione Arquivos" fade scrollable centered size="lg" hide-footer
      v-model="showUploadFileModal">
      <uploadFile :updateFile="(data) => (files = data)" :state="showUploadFileModal" />
      <div class="d-grid gap-2">
        <b-button type="button" class="btn btn-sm btn-success" @click="attachFiles">
          Anexar
        </b-button>
      </div>
    </b-modal>
  </div>
</template>