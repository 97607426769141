import Swal from "sweetalert2";
import { getValueListById } from "./forms/getListaValorById";
import { sendFunctionRequest } from "./dynamicTable/sendFunctionRequest";
import store from "@/state/store";
import { v4 as uuidv4 } from "uuid";

const callFunction = async (
    p_function,
    register_id,
    program_schema,
    register_data,
    resetMainAndDetailTableForms,
    onLoadStart,
    onLoadEnd,
    onSuccess,
    goToNextPage
) => {
    const asyncJob = {
        id: uuidv4(),
        title: null,
        message: null,
        status: "running",
    };
    const body = {
        solicita_tfa: p_function.solicita_tfa,
        id_programa: program_schema.id_programa,
        condicao_adicional: "",
        lista_id_empresa:
            JSON.parse(localStorage.getItem("companiesGlobalFilter")) ?? [],
        data_trabalho: JSON.parse(localStorage.getItem("dataTrabalho")),
        navegador: false,
        ordenacao: null,
        quantidade_registros: 0,
        pagina: 0,
        rota: program_schema.rota_backend + p_function.rota_backend,
        dados: {
            [program_schema.sis_tabela.nome_tabela]: {
                registros: [],
            },
        },
    };

    program_schema.sis_tabela.sis_campo_tabela.forEach((col) => {
        if (col.chave_primaria) {
            body.dados[program_schema.sis_tabela.nome_tabela].registros.push({
                [col.nome]: register_id,
            });
        }
    });

    // Register Data
    // 1 -> Boleto
    // 3 -> Carteira
    // 1474606972 -> PIX
    // 256796645 -> TED/DOC

    // Configurar Título Financeiro
    if (p_function.id_funcionalidade === "6fe3cfca-a6bb-4365-9705-54bf2a45cd5a") {
        if (p_function.exige_registro && !register_data) {
            return Swal.fire({
                icon: "error",
                text: `Funcionalide [${p_function.denominacao}] desabilitada em modo de Criação`,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "OK",
            });
        }

        const { id_tipo_cobranca } = register_data;

        if ([1, 3, 1474606972, 256796645].includes(id_tipo_cobranca)) {
            // Boleto, Carteira, PIX, TED/DOC
            let list_id = null;
            let condition = "";
            let preAditionalCondition = "";
            if ([1, 3].includes(id_tipo_cobranca)) {
                list_id = "5e74467f-f4d3-42ca-9097-b5d39bba45f3";
                // condition = `id_empresa = ${register_data.id_empresa} AND situacao = 1`
                preAditionalCondition = `CAST(TO_JSONB(vw_debito_direto_autorizado.*) AS TEXT) ~* `;
            }
            if ([1474606972, 256796645].includes(id_tipo_cobranca)) {
                list_id = "371ee293-9b2a-405a-a027-ad5b83676e24";
                condition = "id_pessoa = " + register_data.id_credor_devedor;
            }
            const res = await getValueListById(list_id, condition);
            store.commit("massActionsModals/setProperty", [
                {
                    key: "showDynamicFilterValueListModal",
                    value: true,
                },
                {
                    key: "preAditionalCondition",
                    value: preAditionalCondition,
                },
                {
                    key: "sis_lista_valor",
                    value: res.sis_lista_valor,
                },
                {
                    key: "onSave",
                    value: (ids_object) => {
                        if (
                            ids_object[res.sis_lista_valor.nome_tabela].registros.length >
                            1 &&
                            p_function.exige_registro
                        ) {
                            return Swal.fire({
                                icon: "warning",
                                text: `É permitido selecionar somente um registro para executar a ação "${p_function.denominacao}".`,
                                confirmButtonText: "OK",
                            });
                        }
                        asyncJob.title =
                            program_schema.denominacao + " > " + p_function.denominacao;
                        asyncJob.message = "Operação em andamento...";
                        asyncJob.status = "running";
                        store.commit("asyncJobs/addJob", asyncJob);

                        sendFunctionRequest(
                            { ...body, dados: { ...body.dados, ...ids_object } },
                            (res) => {
                                asyncJob.message = "Operação concluída!";
                                asyncJob.status = "done";
                                store.commit("asyncJobs/updateJob", { ...asyncJob });

                                Swal.fire({
                                    icon: "success",
                                    text: `Salvo!`,
                                    confirmButtonText: "OK",
                                });

                                resetMainAndDetailTableForms(res.data.retorno.dados);
                            },
                            (error) => {
                                asyncJob.message = error;
                                asyncJob.status = "failed";
                                store.commit("asyncJobs/updateJob", { ...asyncJob });
                            }
                        );
                    },
                },
            ]);
        }
    }else if (p_function.id_funcionalidade === "6d22f883-df90-4d94-9077-4d0ce3ec516a") {// Configurar Boleto
        if (p_function.exige_registro && !register_data) {
            return Swal.fire({
                icon: "error",
                text: `Funcionalide [${p_function.denominacao}] desabilitada em modo de Criação`,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "OK",
            });
        }

        let list_id = "5e74467f-f4d3-42ca-9097-b5d39bba45f3";
        let condition = "";
        let preAditionalCondition = `CAST(TO_JSONB(vw_debito_direto_autorizado.*) AS TEXT) ~* `;
        const res = await getValueListById(list_id, condition);
        store.commit("massActionsModals/setProperty", [
            {
                key: "showDynamicFilterValueListModal",
                value: true,
            },
            {
                key: "preAditionalCondition",
                value: preAditionalCondition,
            },
            {
                key: "sis_lista_valor",
                value: res.sis_lista_valor,
            },
            {
                key: "onSave",
                value: (ids_object) => {
                    if (
                        ids_object[res.sis_lista_valor.nome_tabela].registros.length > 1 &&
                        p_function.exige_registro
                    ) {
                        return Swal.fire({
                            icon: "warning",
                            text: `É permitido selecionar somente um registro para executar a ação "${p_function.denominacao}".`,
                            confirmButtonText: "OK",
                        });
                    }
                    asyncJob.title =
                        program_schema.denominacao + " > " + p_function.denominacao;
                    asyncJob.message = "Operação em andamento...";
                    asyncJob.status = "running";
                    store.commit("asyncJobs/addJob", asyncJob);

                    sendFunctionRequest(
                        { ...body, dados: { ...body.dados, ...ids_object } },
                        (res) => {
                            asyncJob.message = "Operação concluída!";
                            asyncJob.status = "done";
                            store.commit("asyncJobs/updateJob", { ...asyncJob });

                            Swal.fire({
                                icon: "success",
                                text: `Salvo!`,
                                confirmButtonText: "OK",
                            });

                            resetMainAndDetailTableForms(res.data.retorno.dados);
                        },
                        (error) => {
                            asyncJob.message = error;
                            asyncJob.status = "failed";
                            store.commit("asyncJobs/updateJob", { ...asyncJob });
                        }
                    );
                },
            },
        ]);
    }else if (p_function.id_funcionalidade === "84a4e6a3-967d-44ec-92bf-517002fb6c4a" ||
        p_function.id_funcionalidade === "75c360a4-80b8-481a-ab91-41e9d303c409") {// Configurar PIX OU Configurar TED/DOC
        
        if (p_function.exige_registro && !register_data) {
            return Swal.fire({
                icon: "error",
                text: `Funcionalide [${p_function.denominacao}] desabilitada em modo de Criação`,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "OK",
            });
        }

        let list_id = "371ee293-9b2a-405a-a027-ad5b83676e24";
        let condition = "id_pessoa = " + register_data.id_credor_devedor;
        let preAditionalCondition = ""
        const res = await getValueListById(list_id, condition);
        store.commit("massActionsModals/setProperty", [
            {
                key: "showDynamicFilterValueListModal",
                value: true,
            },
            {
                key: "preAditionalCondition",
                value: preAditionalCondition,
            },
            {
                key: "sis_lista_valor",
                value: res.sis_lista_valor,
            },
            {
                key: "onSave",
                value: (ids_object) => {
                    if (
                        ids_object[res.sis_lista_valor.nome_tabela].registros.length > 1 &&
                        p_function.exige_registro
                    ) {
                        return Swal.fire({
                            icon: "warning",
                            text: `É permitido selecionar somente um registro para executar a ação "${p_function.denominacao}".`,
                            confirmButtonText: "OK",
                        });
                    }
                    asyncJob.title =
                        program_schema.denominacao + " > " + p_function.denominacao;
                    asyncJob.message = "Operação em andamento...";
                    asyncJob.status = "running";
                    store.commit("asyncJobs/addJob", asyncJob);

                    sendFunctionRequest(
                        { ...body, dados: { ...body.dados, ...ids_object } },
                        (res) => {
                            asyncJob.message = "Operação concluída!";
                            asyncJob.status = "done";
                            store.commit("asyncJobs/updateJob", { ...asyncJob });

                            Swal.fire({
                                icon: "success",
                                text: `Salvo!`,
                                confirmButtonText: "OK",
                            });

                            resetMainAndDetailTableForms(res.data.retorno.dados);
                        },
                        (error) => {
                            asyncJob.message = error;
                            asyncJob.status = "failed";
                            store.commit("asyncJobs/updateJob", { ...asyncJob });
                        }
                    );
                },
            },
        ]);
    }else if(p_function.id_funcionalidade === "f8768f85-670f-4d87-a657-3b62f3f2243f") {// Agendar pagamento
        const financialAccountIdListaValor = "9731677c-8dfa-4bf5-8832-c25ab05be9ab"
        const typeOfChargeIdListaValor = "4c8d1b30-6479-48d3-aa08-de583c2b2911"
        const typeOfQualificationListaValor = "ba3e815d-88fc-401f-b310-43c60a732620"
        const companiesListaValor = "83c05a92-5148-44a7-9703-9261f7d5cf11"
        const titlesFromListaValor = "c043efc0-edd5-4149-9500-3170978f8878"
        
        store.commit('contasPagar/setTitle', p_function.denominacao);
        store.commit('contasPagar/setShow', true);
        const [financialAccount, typeOfCharge, typeOfQualification, companies, titlesFrom] = await Promise.all([
            getValueListById(financialAccountIdListaValor),
            getValueListById(typeOfChargeIdListaValor),
            getValueListById(typeOfQualificationListaValor),
            getValueListById(companiesListaValor),
            getValueListById(titlesFromListaValor)
        ])
        
        store.commit('contasPagar/setListFinancialAccounts', financialAccount.sis_lista_valor.sis_opcao_lista_valor);
        store.commit('contasPagar/setListOfTypeCharge', typeOfCharge.sis_lista_valor.sis_opcao_lista_valor);
        store.commit('contasPagar/setListOfTypesOfQualification', typeOfQualification.sis_lista_valor.sis_opcao_lista_valor);
        store.commit('contasPagar/setListOfCompanies', companies.sis_lista_valor.sis_opcao_lista_valor);
        store.commit('contasPagar/setListOfTitlesFrom', titlesFrom.sis_lista_valor.sis_opcao_lista_valor);

        store.commit('contasPagar/setOnSave', (schedulePayment) => {
            const continue_on_save = () => {
                if(onLoadStart){
                    onLoadStart()
                }
                sendFunctionRequest(
                    {
                        ...body,
                        dados: {
                            ...body.dados,
                            ["agendar_pagamento"]: { registros: [schedulePayment] }
                        }
                    },
                    (res) => {
                        Swal.fire({
                            icon: "success",
                            text: `Salvo!`,
                            confirmButtonText: "OK",
                        })
                        store.commit('contasPagar/setShow', false);
                        if(onLoadEnd){
                            onLoadEnd()
                        }
                        if(onSuccess){
                            onSuccess(res)
                        }
                    },
                    () => {
                        if(onLoadEnd){
                            onLoadEnd()
                        }
                    }
                )
            }
            if(register_data.qtde_aprovacao > 0){
                Swal.fire({
                    icon: "error",
                    html: `<p>O seguinte ID perderá as aprovações<br>
                            <p>Tem certeza que deseja continuar?</p>`,
                    confirmButtonText: "OK",
                }).then((result) => {
                    if(result.value){
                        continue_on_save()
                    }
                })
            }else{
                continue_on_save()
            }
        });
    }else if(p_function.id_funcionalidade === "636b7c40-7ef4-42d4-b064-f0e2d7eb7ee8") {// Reprovar Pagamento
        store.commit('massActionsModals/setProperty', [
            {
                key: 'showPagamentoReprovadoModal',
                value: true
            },
            {
                key: 'title',
                value: p_function.denominacao
            },
            {
                key: 'objects',
                value: [register_data]
            },
            {
                key: 'onSave',
                value: (objects) => {
                    return sendFunctionRequest(
                        { ...body, dados: { ...body.dados, ...objects } },
                        (res) => {
                            Swal.fire({
                                icon: "success",
                                text: `Salvo!`,
                                confirmButtonText: "OK",
                            })
                            if(onLoadEnd){
                                onLoadEnd()
                            }
                            if(onSuccess){
                                onSuccess(res)
                            }
                        },
                        () => {
                            if(onLoadEnd){
                                onLoadEnd()
                            }
                        },
                    )
                }
            }
        ])
    } else if (p_function.id_funcionalidade === "c8d1240f-680d-49c2-bf18-64d53ef47430" || p_function.id_funcionalidade === "1de8c5d7-fb67-41d8-b990-eb76ccc6c115") {// Configurar QR Code OR Configurar Tributo/Concessionaria
        if (p_function.exige_registro && !register_data) {
            return Swal.fire({
                icon: "error",
                text: `Funcionalide [${p_function.denominacao}] desabilitada em modo de Criação`,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "OK",
            });
        }

        store.commit("massActionsModals/setProperty", [
            {
                key: "showConfigModal",
                value: true,
            },
            {
                key: "function",
                value: p_function,
            },
            {
                key: "program_schema",
                value: program_schema
            },
            {
                key: "registro_id",
                value: register_id
            },
            {
                key: "onSave",
                value: (bool) => {
                    if (bool) {
                        Swal.fire({
                            icon: "success",
                            text: `Salvo!`,
                            confirmButtonText: "OK",
                        });
                    }
                },
            },
        ]);

    } else {
        if (onLoadStart) {
            onLoadStart()
        }
        sendFunctionRequest(
            body,
            (res) => {
                asyncJob.message = "Operação concluída!";
                asyncJob.status = "done";
                store.commit("asyncJobs/updateJob", { ...asyncJob });

                if (!['bc46e597-ada3-45db-b1c9-95726e764b9c', '3573eb53-1c26-4b8c-af80-8e2b9d25ae10'].includes(p_function.id_funcionalidade)) {
                    Swal.fire({
                        icon: "success",
                        text: `Salvo!`,
                        confirmButtonText: "OK",
                    });   
                }

                if(onLoadEnd){
                    onLoadEnd()
                }
                if(onSuccess){
                    onSuccess(res)
                    if(p_function.id_funcionalidade === 'bc46e597-ada3-45db-b1c9-95726e764b9c'){ // Aprovar Pagamento
                        goToNextPage()
                    }
                }
            },
            () => {
                if(onLoadEnd){
                    onLoadEnd()
                }
            }
        );
    }
};

export { callFunction };
